import { render, staticRenderFns } from "./lifeImg.vue?vue&type=template&id=737da948&scoped=true&"
import script from "./lifeImg.vue?vue&type=script&lang=js&"
export * from "./lifeImg.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "737da948",
  null
  
)

export default component.exports