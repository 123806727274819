<template>
        <div class="friendImg">
            <!-- 背景图 -->
<!--            <img class="headBack" src="http://img.fuqz.cc/life-head.jpg" alt="">-->
            <div class="head_title">
                <span>{{text}}</span><br/><br/><br/>
                <span>{{producre}}</span>
            </div>
        </div>
</template>

<script>
    export default {
        name: "friendImg",
        data(){
            return{
                /* 标题 */
                text:'说说',
                producre:'记录生活的点点滴滴'
            }
        },
        }
</script>

<style scoped>

</style>
